import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const Paralax = ({ title, imgSrc }) => {
 
  return (
    <ParalaxWrapper>
      <BackgroundImage
        role="img"
        fluid={imgSrc}
        fadeIn={"soft"}
        className="parallax"
        style={{
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "50% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pagehero-bg">
          <div>
            <h1>{title}</h1>
          </div>
        </div>
      </BackgroundImage>
    </ParalaxWrapper>
  )
}

const ParalaxWrapper = styled.section`
border-bottom: 4px solid var(--maincolor);
  opacity: 1 !important;

  .parallax {
    height: 45vh;
  }

  .pagehero-bg {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        150deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.9) 100%
      ),
      linear-gradient(100deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    display: flex;
    align-items: end;
    justify-items: row;
    justify-content: center;
    text-align: center;
  }

  h1 {
    text-transform: uppercase;
    text-shadow: 4px 5px rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: bold;
    letter-spacing: var(--mainspacing);
    font-size: 3rem;
  }

  @media (min-width: 800px) {
    .parallax {
      height: 60vh;
    }
  }

  @media (max-width: 799px) {
    padding-top: var(--navbarheight);
    h1 {
      font-size: 2rem;
    }
  }
`

export default Paralax
