import React, { useEffect, useRef } from "react"
import L from "leaflet"

const style = {
  width: "100%",
  height: "100%",
  minHeight: "400px",
}

function Map({ markerPosition }) {
  // create map
  const mapRef = useRef(null)
  useEffect(() => {
    mapRef.current = L.map("map", {
      // 41.995374815632886, 21.398966961968085

      center: [41.995233,21.398906],
      zoom: 16,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    })
  }, [])

  // add marker
  const markerRef = useRef(null)
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLatLng(markerPosition)
    } else {
      markerRef.current = L.marker(markerPosition).addTo(mapRef.current)
    }
  }, [markerPosition])

  return <div id="map" style={style} />
}

export default Map
