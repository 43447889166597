import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PageHero from "../components/banners/PageHero"
import LeafletMap from "../components/map/Leafletmap"
import Img from "gatsby-image"

const AboutPage = ({ data }) => {
  const [markerPosition] = useState({
    lat: 41.995233,
    lng: 21.398906,
  })

  const PageContent = data.allContentfulPageSettings.nodes[0]

  console.log(PageContent)

  return (
    <>
      <PageHero title="за канцона" imgSrc={PageContent.pageAboutImage.fluid} />
      <SectionWrapper>
        <div className="contactContainer">
          <section className="pb-4">
            <h2>{PageContent.aboutTitle}</h2>
            <span className="p-4">{PageContent.aboutText1.aboutText1}</span>
            <span className="p-4">{PageContent.aboutText2.aboutText2}</span>
          </section>
          <section>
            <Img fluid={PageContent.aboutImages[0].fluid} />
          </section>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <div className="contactContainer reverse">
          <section>
            <h2>Контакт</h2>
            <span>
              <a href={`tel:${data.site.siteMetadata.phone}`}>
                {data.site.siteMetadata.phoneSeparate}
              </a>
            </span>
            <span>{data.site.siteMetadata.email}</span>
            <h2>Адреса</h2>
            <span style={{ width: "95%", margin: "0 auto" }}>
              {data.site.siteMetadata.address}
            </span>
            <h2>Работно време</h2>
            <div className="table">
              <ul>
                <li>Понеделник - Четврток </li>
                <li>Петок - Сабота</li>
                <li>Недела</li>
              </ul>
              <ul>
                <li> 8:00-00:00</li>
                <li>8:00-01:00</li>
                <li>затворено</li>
              </ul>
            </div>
          </section>
          <section>
            <LeafletMap markerPosition={markerPosition} />
          </section>
        </div>
      </SectionWrapper>
    </>
  )
}

const SectionWrapper = styled.div`
  .contactContainer {
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
      flex-direction: row;

      &.reverse {
        flex-direction: row-reverse;
      }
    }
    @media screen and (min-width: 1824px) {
    }

    section {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 1024px) {
        width: 50%;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
      .table {
        display: flex;
        justify-content: space-around;
        align-items: center;
        ul {
          list-style-type: none;
          @media screen and (min-width: 1824px) {
            font-size: 22px;
          }
        }
      }
      h2 {
        padding-top: 60px;
        @media screen and (min-width: 1824px) {
          font-size: 48px;
        }
      }
      span {
        padding-top: 5px;
        @media screen and (min-width: 1824px) {
          font-size: 22px;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    background-attachment: fixed;
    background-size: cover;
    background-position: 60% 50%;
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    min-height: 300px;
  }
`

export default AboutPage

export const query = graphql`
  query {
    allContentfulPageSettings {
      nodes {
        aboutTitle
        aboutText1 {
          aboutText1
        }
        aboutText2 {
          aboutText2
        }
        aboutImages {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        pageAboutImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }

    site {
      siteMetadata {
        address
        phone
        phoneSeparate
        email
      }
    }
  }
`
